import { Suspense, lazy, ElementType } from 'react'
// components
import LoadingScreen from '../components/loading-screen'

// ----------------------------------------------------------------------

const Loadable = (Component: ElementType) => (props: any) =>
	(
		<Suspense fallback={<LoadingScreen />}>
			<Component {...props} />
		</Suspense>
	)

// ----------------------------------------------------------------------

// AUTH
export const LoginPage = Loadable(lazy(() => import('../pages/auth/LoginPage')))
export const RegisterPage = Loadable(lazy(() => import('../pages/auth/RegisterPage')))
export const RegisterSuccessPage = Loadable(lazy(() => import('../pages/auth/RegisterSuccess')))
export const RegisterErrorPage = Loadable(lazy(() => import('../pages/auth/RegisterError')))
export const VerifyCodePage = Loadable(lazy(() => import('../pages/auth/VerifyCodePage')))
export const NewPasswordPage = Loadable(lazy(() => import('../pages/auth/NewPasswordPage')))
export const ResetPasswordPage = Loadable(lazy(() => import('../pages/auth/ResetPasswordPage')))

// //CUSTOM
// export const PageOne = Loadable(lazy(() => import('../pages/PageOne')))
// export const PageTwo = Loadable(lazy(() => import('../pages/PageTwo')))
// export const PageThree = Loadable(lazy(() => import('../pages/PageThree')))
// export const PageFour = Loadable(lazy(() => import('../pages/PageFour')))
// export const PageFive = Loadable(lazy(() => import('../pages/PageFive')))
// export const PageSix = Loadable(lazy(() => import('../pages/PageSix')))

//MAIN
export const MainPage = Loadable(lazy(() => import('../pages/main/MainPage')))
export const VideosPage = Loadable(lazy(() => import('../pages/videos/VideosPage')))
export const BroadcastPage = Loadable(lazy(() => import('../pages/broadcast/BroadcastPage')))
export const WatchVideoPage = Loadable(lazy(() => import('../pages/video/WatchVideoPage')))
export const SubscriptionsPage = Loadable(lazy(() => import('../pages/SubscriptionsPage')))
export const ChannelPage = Loadable(lazy(() => import('../pages/channel/ChannelPage')))
export const LibraryPage = Loadable(lazy(() => import('../pages/LibraryPage')))
export const HistoryPage = Loadable(lazy(() => import('../pages/HistoryPage')))
export const LikedPage = Loadable(lazy(() => import('../pages/LikedPage')))
export const ContentListPage = Loadable(lazy(() => import('../pages/content/ListPage')))
export const VideoFormPage = Loadable(lazy(() => import('../pages/content/video/FormPage')))
export const StreamFormPage = Loadable(lazy(() => import('../pages/content/stream/FormPage')))
export const WatchStreamPage = Loadable(lazy(() => import('../pages/stream/WatchStreamPage')))
export const SearchPage = Loadable(lazy(() => import('../pages/search/SearchPage')))
export const BlogsPage = Loadable(lazy(() => import('../pages/blogs/BlogsPage')))
export const BlogEditForm = Loadable(lazy(() => import('../pages/content/blog/FormPage')))
export const PlaylistEditForm = Loadable(lazy(() => import('../pages/content/playlist/FormPage')))
export const BlogForm = Loadable(lazy(() => import('../sections/blog/BlogForm')))
export const SupportPage = Loadable(lazy(() => import('../pages/support/SupportPage')))
export const AccountPage = Loadable(lazy(() => import('../pages/account/AccountPage')))
export const ModerationPage = Loadable(lazy(() => import('../pages/moderation/ModerationPage')))

//SERVICE
export const Page500 = Loadable(lazy(() => import('../pages/service/Page500')))
export const Page403 = Loadable(lazy(() => import('../pages/service/Page403')))
export const Page404 = Loadable(lazy(() => import('../pages/service/Page404')))
export const ComingSoonPage = Loadable(lazy(() => import('../pages/service/ComingSoonPage')))
export const MaintenancePage = Loadable(lazy(() => import('../pages/service/MaintenancePage')))

// ADMINISTRATION
export const AdministrationPage = Loadable(
	lazy(() => import('../pages/administration/AdministrationPage'))
)

export const SubscribersVideoPage = Loadable(
	lazy(() => import('../pages/administration/SubscribersVideoPage'))
)

export const AdvertisingForm = Loadable(
	lazy(() => import('../pages/administration/AdvertisingForm'))
)

//EMBEDDED
export const EmbeddedVideoPage = Loadable(lazy(() => import('../pages/embedded/WatchVideoPage')))

export const ChatPage = Loadable(lazy(() => import('../pages/ChatPage')))

export const EmbeddedStreamPage = Loadable(lazy(() => import('../pages/embedded/WatchStreamPage')))

