import { forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
// @mui
import { Box, Link, BoxProps } from '@mui/material'
// components
// import SvgColor from 'src/components/svg-color/SvgColor'
import Image from 'src/components/image/Image'

// ----------------------------------------------------------------------

export interface LogoProps extends BoxProps {
	disabledLink?: boolean
	children?: React.ReactNode
}

const Logo = forwardRef<HTMLDivElement, LogoProps>(
	({ disabledLink = false, sx, children, ...other }, ref) => {
		const logo = (
			<Box
				ref={ref}
				component='div'
				sx={{
					width: 40,
					height: 40,
					display: 'inline-flex',
					...sx,
				}}
				{...other}
			>
				<Image
					disabledEffect
					src='/assets/icons/flags/ic_flag_ru.svg'
					sx={{
						'& img': {
							objectFit: 'contain !important',
						},
					}}
				/>
			</Box>
		)

		if (disabledLink) {
			return logo
		}

		if (children) {
			return (
				<Link
					to='/'
					component={RouterLink}
					sx={{
						display: 'contents',
						color: 'inherit',
					}}
				>
					<Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
						{logo}
						{children}
					</Box>
				</Link>
			)
		}

		return (
			<Link component={RouterLink} to='/' sx={{ display: 'contents' }}>
				{logo}
			</Link>
		)
	}
)

export default Logo
