import React, { useState, useEffect } from 'react'
// @mui
import { Box } from '@mui/material'
// components
import { Outlet } from 'react-router-dom'
import Header from './Header'
import NavVertical from './nav/NavVertical'
import NavMini from './nav/NavMini'
// utils
import useResponsive from 'src/hooks/useResponsive'
import { NAV, HEADER } from 'src/config-global'
import { useSnackbar } from 'src/components/snackbar'
import SnackbarUtils from 'src/utils/snackbar'
//
import { useSettingsContext } from "../../components/settings";
import { alpha } from "@mui/material/styles";
import { useDispatch, useSelector } from "../../redux/store";
import { getBackground } from "../../redux/slices/background";

// ----------------------------------------------------------------------

export default function MainLayout() {
	const [asideOpen, setAsideOpen] = useState(false)
	const dispatch = useDispatch()
	const { background } = useSelector(state => state.background)
	const { onChangeBackground } = useSettingsContext()

	useEffect(()=> {
		dispatch(getBackground())
	},[dispatch])

	useEffect(() => {
		onChangeBackground(background)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [background])

	const { enqueueSnackbar, closeSnackbar } = useSnackbar()
	// const handleAsideOpen = () => {
	// 	setAsideOpen(true)
	// }

	useEffect(() => {
		SnackbarUtils.setSnackBar(enqueueSnackbar, closeSnackbar)
	}, [enqueueSnackbar, closeSnackbar])

	const handleAsideClose = () => {
		setAsideOpen(false)
	}

	const handleAsideToggle = () => {
		setAsideOpen(prev => !prev)
	}

	const isDesktop = useResponsive('up', 'lg')

	useEffect(() => {
		if (isDesktop && !asideOpen) {
			setAsideOpen(true)
		}

		if (!isDesktop && asideOpen) {
			setAsideOpen(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isDesktop])

	return (
		<Box sx={{ display: 'flex', flexDirection: 'column', height: 1 }} >
			<Header asideToggle={handleAsideToggle} />

			<Box
				className={'main-layout'}
				component='main'
				pt={HEADER.H_MAIN_DESKTOP}
				sx={{
					flexGrow: 1,
					backgroundColor: background?.background ? theme => alpha(theme.palette.background.paper, 0.6) : theme => theme.palette.background.paper,
				}}
			>
				<Box className={'translucent-background'} width={'100%'} height={'100%'}>
				{asideOpen ? (
					<NavVertical openNav={asideOpen} onCloseNav={handleAsideClose} />
				) : (
					<NavMini />
				)}
				<Box
					sx={{
						paddingLeft: `${isDesktop && asideOpen ? NAV.W_MAIN : NAV.W_DASHBOARD_MINI}px`,
					}}
				>
					<Outlet />
				</Box>
				</Box>
			</Box>
		</Box>
	)
}
