import { Paper, PaperProps, Typography } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends PaperProps {
	query?: string;
}

export default function SearchNotFound({ query, sx, ...other }: Props) {
	return query ? (
		<Paper
			sx={{
				textAlign: 'center',
				...sx,
			}}
			{...other}
		>
			<Typography variant="h6" paragraph>
				Не найдено
			</Typography>

			<Typography variant="body2">
				Нет результатов для значения &nbsp;
				<strong>&quot;{query}&quot;</strong>.
				<br /> Попробуйте изменить запрос
			</Typography>
		</Paper>
	) : (
		<Typography variant="body2" sx={sx}>
			Пожалуйста введите запрос
		</Typography>
	);
}
