import { Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'
import { useSettingsContext } from 'src/components/settings'
import { getPublicVideo } from 'src/redux/slices/video'
import { useDispatch, useSelector } from 'src/redux/store'
import WatchVideoForm from 'src/sections/video/WatchVideoForm'
import { getPlaylist } from "../../redux/slices/playlist";

// ----------------------------------------------------------------------

export default function PlaylistPage() {
    const { themeStretch } = useSettingsContext()
    const [title, setTitle] = useState<string>('')

    const { uuid, id } = useParams()

    const dispatch = useDispatch()
    const { playlist } = useSelector(state => state.playlist)
    const handleSetTitle = (title: string) => {
        setTitle(title)
    }


    useEffect(() => {
        if (id) {
            dispatch(getPlaylist(id))
        }

    }, [uuid, id, dispatch])

    useEffect(() => {
        if (uuid) {

            if (playlist?.items_ids) {
                dispatch(getPublicVideo(uuid))

            }
        }
    })

    useEffect(() => {
        if (playlist) {
            handleSetTitle(playlist.name)
        }
    }, [playlist])

    return (
        <>
            <Helmet>
                <title>{title}</title>
            </Helmet>

            <Container
                maxWidth={themeStretch ? false : 'lg'}
                sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
            >
                <WatchVideoForm isItemPlaylist={true} />
            </Container>
        </>
    )
}
