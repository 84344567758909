import { Container } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async'
import { useParams } from 'react-router'
import { useSettingsContext } from 'src/components/settings'
import { getPublicVideo } from 'src/redux/slices/video'
import { useDispatch, useSelector } from 'src/redux/store'
import WatchVideoForm from 'src/sections/video/WatchVideoForm'

// ----------------------------------------------------------------------

export default function WatchVideoPage() {
	const { themeStretch } = useSettingsContext()
	const [title, setTitle] = useState<string>('')

	const { uuid } = useParams()

	const dispatch = useDispatch()
	const { video } = useSelector(state => state.video)

	const handleSetTitle = (title: string) => {
		setTitle(title)
	}

	useEffect(() => {
		if (uuid) {
			handleSetTitle(uuid ?? '')
			dispatch(getPublicVideo(uuid))
		}
	}, [uuid, dispatch])

	useEffect(() => {
		if (video) {
			handleSetTitle(video.name)
		}
	}, [video])

	return (
		<>
			<Helmet>
				<title>{title}</title>
			</Helmet>

			<Container
				maxWidth={themeStretch ? false : 'lg'}
				sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}
			>
				<WatchVideoForm />
			</Container>
		</>
	)
}
