// API
// ----------------------------------------------------------------------

export const HOST_API_KEY = process.env.REACT_APP_HOST_API_KEY || ''
export const RTMP_HOST_API = process.env.REACT_APP_RTMP_HOST_API || ''
export const WS_HOST = process.env.REACT_APP_WS_HOST || ''

export const FIREBASE_API = {
	apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
	authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
	projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
	storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
	messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
	appId: process.env.REACT_APP_FIREBASE_APPID,
	measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
}

export const COGNITO_API = {
	userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
	clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
}

export const AUTH0_API = {
	clientId: process.env.REACT_APP_AUTH0_CLIENT_ID,
	domain: process.env.REACT_APP_AUTH0_DOMAIN,
}

export const MAP_API = process.env.REACT_APP_MAPBOX_API

// ROOT PATH AFTER LOGIN SUCCESSFUL
export const PATH_AFTER_LOGIN = '/'

// LAYOUT
// ----------------------------------------------------------------------

export const HEADER = {
	H_MOBILE: 64,
	H_MAIN_DESKTOP: 7, // In rem's
	H_DASHBOARD_DESKTOP: 92,
	H_DASHBOARD_DESKTOP_OFFSET: 92 - 32,
}

export const NAV = {
	W_BASE: 260,
	W_DASHBOARD: 280,
	W_DASHBOARD_MINI: 64,
	W_MAIN: 240,
	//
	H_DASHBOARD_ITEM: 48,
	H_DASHBOARD_ITEM_SUB: 36,
	//
	H_DASHBOARD_ITEM_HORIZONTAL: 32,
}

export const ICON = {
	NAV_ITEM: 24,
	NAV_ITEM_HORIZONTAL: 22,
	NAV_ITEM_MINI: 22,
}
