import { Navigate, useRoutes } from 'react-router-dom'
// auth
import AuthGuard from 'src/auth/AuthGuard'
import GuestGuard from 'src/auth/GuestGuard'
// layouts
import MainLayout from 'src/layouts/main/MainLayout'
import CompactLayout from 'src/layouts/compact'
import DashboardLayout from 'src/layouts/dashboard'
import EmbeddedLayout from 'src/layouts/embedded'
// config
import { PATH_AFTER_LOGIN } from 'src/config-global'
//
import {
	// Auth
	LoginPage,
	RegisterPage,
	RegisterSuccessPage,
	RegisterErrorPage,
	VerifyCodePage,
	NewPasswordPage,
	ResetPasswordPage,
	ChannelPage,
	Page500,
	Page403,
	Page404,
	MainPage,
	// PageOne,
	// PageTwo,
	// PageSix,
	// PageFour,
	// PageFive,
	// PageThree,
	ComingSoonPage,
	MaintenancePage,
	LibraryPage,
	HistoryPage,
	SubscriptionsPage,
	LikedPage,
	ContentListPage,
	VideoFormPage,
	WatchStreamPage,
	StreamFormPage,
	BroadcastPage,
	SubscribersVideoPage,
	EmbeddedVideoPage,
	SearchPage,
	BlogsPage,
	BlogForm,
	BlogEditForm,
	ChatPage,
	AdvertisingForm,
	SupportPage,
	AdministrationPage,
	AccountPage,
	VideosPage,
	ModerationPage,
	PlaylistEditForm,
	EmbeddedStreamPage,
} from './elements'
import WatchVideoPage from 'src/pages/video/WatchVideoPage'
import RoleBasedGuard from 'src/auth/RoleBasedGuard'
import PlaylistPage from "../pages/playlist/PlaylistPage";

// ----------------------------------------------------------------------

export default function Router() {
	return useRoutes([
		// Auth
		{
			path: 'auth',
			children: [
				{
					path: 'login',
					element: (
						<GuestGuard>
							<LoginPage />
						</GuestGuard>
					),
				},
				{
					path: 'register',
					element: (
						<GuestGuard>
							<RegisterPage />
						</GuestGuard>
					),
				},
				{ path: 'login-unprotected', element: <LoginPage /> },
				{ path: 'register-unprotected', element: <RegisterPage /> },
				{
					element: <CompactLayout />,
					children: [
						{ path: 'reset-password', element: <ResetPasswordPage /> },
						{ path: 'new-password', element: <NewPasswordPage /> },
						{ path: 'verify', element: <VerifyCodePage /> },
						{ path: 'register-success', element: <RegisterSuccessPage /> },
						{ path: 'register-error', element: <RegisterErrorPage /> },
					],
				},
			],
		},

		//Dashboard
		{
			path: '/dashboard',
			element: (
				<AuthGuard>
					<DashboardLayout />
				</AuthGuard>
			),
			children: [
				{ element: <Navigate to={PATH_AFTER_LOGIN} replace />, index: true },
				// { path: 'one', element: <PageOne /> },
				// { path: 'two', element: <PageTwo /> },
				// { path: 'three', element: <PageThree /> },
				{
					path: 'user',
					children: [
						{ element: <Navigate to='/dashboard/user/four' replace />, index: true },
						// { path: 'four', element: <PageFour /> },
						// { path: 'five', element: <PageFive /> },
						// { path: 'six', element: <PageSix /> },
					],
				},
			],
		},

		// Main Routes
		{
			element: <MainLayout />,
			children: [
				{ element: <Navigate to='/home' replace />, index: true },
				{ path: 'home', element: <MainPage /> },
				{ path: 'videos', element: <VideosPage /> },
				{ path: 'broadcast', element: <BroadcastPage /> },
				{ path: 'channel/:uuid', element: <ChannelPage /> },
				{ path: 'watch/:uuid', element: <WatchVideoPage /> },
				{ path: 'library', element: <LibraryPage /> },
				{ path: 'subscriptions', element: <SubscriptionsPage /> },
				{ path: 'history', element: <HistoryPage /> },
				{ path: 'stream/:uuid', element: <WatchStreamPage /> },
				{ path: 'results', element: <SearchPage /> },
				{ path: 'playlist/:id/:uuid', element: <PlaylistPage /> },
				{
					path: 'content',
					children: [
						{ element: <Navigate to='/content/list' />, index: true },
						{
							path: 'list',
							element: (
								<AuthGuard>
									<ContentListPage />
								</AuthGuard>
							),
						},
						{
							path: 'video/:uuid/edit',
							element: (
								<AuthGuard>
									<VideoFormPage />
								</AuthGuard>
							),
						},
						{
							path: 'stream/:uuid/edit',
							element: (
								<AuthGuard>
									<StreamFormPage />
								</AuthGuard>
							),
						},
						{
							path: 'blog/:uuid/edit',
							element: (
								<AuthGuard>
									<BlogEditForm />
								</AuthGuard>
							),
						},
						{
							path: 'playlist/:uuid/edit',
							element: (
								<AuthGuard>
									<PlaylistEditForm />
								</AuthGuard>
							),
						},
					],
				},
				{
					path: 'chat',
					children: [
						{ element: <ChatPage />, index: true },
						{
							path: ':uuid',
							element: <ChatPage />,
						},
					]
				},
				{
					path: '/liked',
					element: (
						<AuthGuard>
							<LikedPage />
						</AuthGuard>
					),
				},
				{
					path: '/moderation',
					element: <ModerationPage />,
				},
				{
					path: 'blogs',
					children: [
						{ element: <Navigate to='/blogs/list' replace />, index: true },
						{
							path: 'list',
							element: <BlogsPage />,
						},
						{
							path: ':uuid',
							element: <BlogForm />,
						},
					],
				},
				{
					path: '/support',
					element: <SupportPage />,
				},
				{
					path: '/account',
					element: <AccountPage />,
				},
				{
					path: 'administration',
					children: [
						{
							path: '',
							element: (
								<RoleBasedGuard roles={['admin']} hasContent>
									<AdministrationPage />
								</RoleBasedGuard>
							),
						},
						{
							path: 'subscribes',
							element: (
								<RoleBasedGuard roles={['admin']} hasContent>
									<AdministrationPage tab='subscribes' />
								</RoleBasedGuard>
							),
						},
						{
							path: 'subscribes/:id',
							element: (
								<RoleBasedGuard roles={['admin']} hasContent>
									<SubscribersVideoPage />
								</RoleBasedGuard>
							),
						},
						{
							path: 'advertising',
							children: [
								{
									path: '',
									element: (
										<RoleBasedGuard roles={['admin']} hasContent>
											<AdministrationPage tab='advertising' />
										</RoleBasedGuard>
									),
								},
								{
									path: ':id',
									element: (
										<RoleBasedGuard roles={['admin']} hasContent>
											<AdvertisingForm />
										</RoleBasedGuard>
									),
								},
								{
									path: 'new',
									element: (
										<RoleBasedGuard roles={['admin']} hasContent>
											<AdvertisingForm />
										</RoleBasedGuard>
									),
								},
							],
						},
						{
							path: 'support',
							element: (
								<RoleBasedGuard roles={['admin']} hasContent>
									<AdministrationPage tab='support' />
								</RoleBasedGuard>
							),
						},
						{
							path: 'support/:id',
							element: (
								<RoleBasedGuard roles={['admin']} hasContent>
									<SupportPage />
								</RoleBasedGuard>
							),
						},
					],
				},
			],
		},

		{
			element: <EmbeddedLayout />,
			children: [
				{
					path: 'embedded/video/:uuid',
					element: <EmbeddedVideoPage />
				},
				{
					path: 'embedded/stream/:uuid',
					element: <EmbeddedStreamPage />
				}
				],
		},

		{
			element: <CompactLayout />,
			children: [
				{ path: 'coming-soon', element: <ComingSoonPage /> },
				{ path: 'maintenance', element: <MaintenancePage /> },
				{ path: '500', element: <Page500 /> },
				{ path: '404', element: <Page404 /> },
				{ path: '403', element: <Page403 /> },
			],
		},
		{ path: '*', element: <Navigate to='/404' replace /> },
	])
}
