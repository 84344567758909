import _mock from '../_mock'
import { randomInArray } from '../utils'

// ----------------------------------------------------------------------

export const _carouselsMembers = [...Array(6)].map((_, index) => ({
	id: _mock.id(index),
	name: _mock.name.fullName(index),
	role: _mock.role(index),
	avatar: `/assets/images/portraits/portrait_${index + 1}.jpg`,
}))

// ----------------------------------------------------------------------

export const _faqs = [...Array(8)].map((_, index) => ({
	id: _mock.id(index),
	value: `panel${index + 1}`,
	heading: `Questions ${index + 1}`,
	detail: _mock.text.description(index),
}))

// ----------------------------------------------------------------------

export const _addressBooks = [...Array(5)].map((_, index) => ({
	id: _mock.id(index),
	receiver: _mock.name.fullName(index),
	fullAddress: _mock.address.fullAddress(index),
	phoneNumber: _mock.phoneNumber(index),
	addressType: index === 0 ? 'Home' : 'Office',
	isDefault: index === 0,
}))

// ----------------------------------------------------------------------

export const _skills = [...Array(3)].map((_, index) => ({
	label: ['Development', 'Design', 'Marketing'][index],
	value: _mock.number.percent(index),
}))

// ----------------------------------------------------------------------

export const _contacts = [...Array(20)].map((_, index) => ({
	id: _mock.id(index),
	name: _mock.name.fullName(index),
	username: _mock.name.fullName(index),
	avatar: _mock.image.avatar(index),
	address: _mock.address.fullAddress(index),
	phone: _mock.phoneNumber(index),
	email: _mock.email(index),
	lastActivity: _mock.time(index),
	status: randomInArray(['online', 'offline', 'away', 'busy']),
	role: _mock.role(index),
}))

// ----------------------------------------------------------------------

export const _notifications = [...Array(5)].map((_, index) => ({
	id: _mock.id(index),
	title: [
		'Your order is placed',
		'Sylvan King',
		'You have new message',
		'You have new mail',
		'Delivery processing',
	][index],
	description: [
		'waiting for shipping',
		'answered to your comment on the Minimal',
		'5 unread messages',
		'sent from Guido Padberg',
		'Your order is being shipped',
	][index],
	avatar: [null, _mock.image.avatar(2), null, null, null][index],
	type: ['order_placed', 'friend_interactive', 'chat_message', 'mail', 'order_shipped'][index],
	createdAt: _mock.time(index),
	isUnRead: [true, true, false, false, false][index],
}))

// ----------------------------------------------------------------------

export const _socials = [
	{
		value: 'facebook',
		name: 'FaceBook',
		icon: 'eva:facebook-fill',
		color: '#1877F2',
		path: 'https://www.facebook.com/caitlyn.kerluke',
	},
	{
		value: 'instagram',
		name: 'Instagram',
		icon: 'ant-design:instagram-filled',
		color: '#E02D69',
		path: 'https://www.instagram.com/caitlyn.kerluke',
	},
	{
		value: 'linkedin',
		name: 'Linkedin',
		icon: 'eva:linkedin-fill',
		color: '#007EBB',
		path: 'https://www.linkedin.com/caitlyn.kerluke',
	},
	{
		value: 'twitter',
		name: 'Twitter',
		icon: 'eva:twitter-fill',
		color: '#00AAEC',
		path: 'https://www.twitter.com/caitlyn.kerluke',
	},
]
